.container {
    display: grid;
    grid-template-columns: 2fr 1fr; /* 2/3 for the first column, 1/3 for the second */
    background-color: #FF9800;
    padding: 20px;
    height: 80vh;
  }
  
  .text-column {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .text-column h1 {
    margin-bottom: 15px;
    font-size: 32pt;
    font-family: 'SF Pro Display', sans-serif;
    color: #FFFFFF;
  }

  .text-column h2 {
    margin-bottom: 15px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18pt;
    line-height: 30px;
    color: #FFFFFF;
  }
  
  .text-column p {
    margin-bottom: 10px;
  }
  
  .slider-column {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100;
  }
  
  .slider {
    position: relative;
    width: 100%;
    max-width: 500px;
  }
  
  .slider-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .slider-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .slider-controls button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 50%;
  }
  
  .slider-controls button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }