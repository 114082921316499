.product-section {
    height: calc(100vh - 100px);
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 32px;
  }
  
  .product-header h2 {
    color: black;
    opacity: 0.6;
  }
  
  .product-content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }
  
  .product-image-container {
    flex: 2;
    margin: 60px 32px;
  }
  
  .product-image {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-image: url('/public/bulk_logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  }
  
  .product-info {
    flex: 2;
    margin: 60px 32px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .product-title {
    color: #06038d;
    font-size: 2.5rem;
  }
  
  .product-description {
    margin: 24px 0;
    font-size: 1.2rem;
  }
  
  .product-divider {
    margin: 20px 0;
    width: 50%;
    border: 1px solid black;
  }
  
  .product-buttons {
    display: flex;
    align-items: center;
  }
  
  .google-play-button {
    display: flex;
    align-items: center;
    border: 1px solid black;
    border-radius: 12px;
    padding: 12px 24px;
    cursor: pointer;
    background: none;
  }
  
  .google-play-button img {
    width: 60px;
    height: 60px;
  }
  
  .button-text {
    margin-left: 24px;
    text-align: left;
  }
  
  .privacy-policy-button {
    margin-left: 60px;
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
  }