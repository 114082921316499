@font-face {
  font-family: 'SFProDisplay';
  src: url('/public/fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'SFProDisplay';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'SFProDisplay';
}
