.animated-button {
    background-color: #4CAF50; /* Initial background */
    color: white;
    padding: 12px 24px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    transition: all 0.4s ease; /* Smooth transition for hover effect */
    position: relative;
    overflow: hidden;
  }
  
  .animated-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.2); /* Effect on hover */
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.75s ease;
    border-radius: 10%;
    opacity: 0;
  }
  
  .animated-button:hover::before {
    width: 0;
    height: 0;
    opacity: 1;
  }
  
  .animated-button:hover {
    background-color: #45a049; /* Change background on hover */
    color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow */
    transform: translateY(-3px); /* Slight lift on hover */
  }
  
  /* For pressed state */
  .animated-button:active {
    transform: translateY(1px); /* Button sinks when clicked */
  }