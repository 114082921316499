body {
  font-family: 'SF Pro Display', sans-serif;
  margin: 0;
}

.navbar {
  background-color: white;
  padding: 1rem;
  color: black;
  display: flex;
  justify-content: start;
  font-family: 'SF Pro Display', sans-serif;
}

.navbar ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Display', sans-serif;
}

.navbar ul li {
  margin-left: 1rem;
  font-family: 'SF Pro Display', sans-serif;
}

.navbar ul li a {
  color: black;
  text-decoration: none;
  font-family: 'SF Pro Display', sans-serif;
}

.about, .projects {
  padding: 2rem;
  text-align: center;
  font-family: 'SF Pro Display', sans-serif;
}

.project {
  margin-bottom: 2rem;
  font-family: 'SF Pro Display', sans-serif;
}

.footer {
  background-color: #2f791c;
  color: white;
  text-align: center;
  padding: 1rem;
  font-family: 'SF Pro Display', sans-serif;
}

.project-div {
  height: 80vh;
  width: 100vw;
  padding-bottom: 40px;
  background-color: white;
  text-align: center;
  border: 2px solid #ccc;
  font-family: 'SF Pro Display', sans-serif;
}



.column {
  flex: 1; /* Each column will take 50% */
  padding: 20px;
  text-align: center;
  border: 1px solid #000;
  font-family: 'SF Pro Display', sans-serif;
}
